<template>
<n-alert class="rounded-lg" type="warning" size="large">
    <template #header>
        <n-collapse arrow-placement="right">
            <n-collapse-item>
                <template #header>
                    <div class="text-xs" v-html="parsed[0]"></div>
                </template>
                <div
                    class="notes text-xs"
                    v-html="parsed.slice(1).join('')">
                </div>
            </n-collapse-item>
        </n-collapse>
    </template>
</n-alert>

<div v-if="init">
    <super-table
        showSearch
        :filters="filters"
        :actions="actions"
        :refFilters="refFilters"
        :pager="marketplace.sellPager"
        :filtersChanged="filtersChanged"
        :columns="marketplace.sellColumns"
        :records="marketplace.sellRecords"
        columnsStorageName="__columns_marketplace-sell"
        type="marketplace.sell"
        @getData="getData"
        @sortColumn="sortColumn"
        @applyFilters="changeFilter"
        @doSomething="doSomething"
        @reset-filters="resetFilters">
        <template #tableTitle>
            <div class="text-base text-left mb-2">
                {{ marketplace.localization['marketplace/buy/grid/title'] }}:
                <span class="text-main font-semibold text-lg">{{ marketplace.sellTotal }}</span>
            </div>
        </template>
    </super-table>
</div>

<base-dialog-desktop
    v-model:show="showModalEdit"
    @positive-click="editBot, showModalEdit = null"
    @negative-click="showModalEdit = null">
    <div class="text-md" v-html="refs.localization.confirmations.bot.marketplace_bot_edit_notice"></div>
</base-dialog-desktop>

<base-dialog-desktop
    v-model:show="showModalDelete"
    @positive-click="confirmlDeleteBot"
    @negative-click="cancelDeleteBot">
    <div class="text-md" v-html="refs.localization.confirmations.bot.remove_from_market"></div>
</base-dialog-desktop>


<!-- <n-modal
  v-model:show="showModalDelete"
  preset="dialog"
  :positive-text="marketplace.localization['marketplace/sell/grid/actions/in_market__yes']"
  :negative-text="marketplace.localization['marketplace/sell/grid/actions/in_market__no']"
  transform-origin="center"
  @positive-click="deleteBot"
  @negative-click="deletedBotId = null">
  <div class="text-base" v-html="refs.localization.confirmations.bot.remove_from_market"></div>
</n-modal> -->

<bot-review-sell
    v-if="marketplace.localization"
    :show="showModal"
    @getData="getData" />
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NSpin,
    NCard,
    NAlert,
    NModal,
    NDivider,
    NSkeleton,
    NCollapse,
    NCollapseItem } from 'naive-ui';

// components
import FilterAlgos from '@components/filters/algos.vue';
import FilterCoins from '@components/filters/coins.vue';
import GridTable from '@components/base/grid-table.vue';
import BotReviewSell from '@components/bot-review-sell/index.vue';
import SuperTable from '@components/super-table/desktop.vue';
import GridSettings from '@components/base/grid-settings.vue';
import ButtonGroup from '@components/filters/button-group.vue';
import FilterExchanges from '@components/filters/exchanges.vue';
import FilterChangedDate from '@components/filters/changedDate.vue';

export default {
    name: 'MarketplaceSell',
    components: {
        NSpin,
        NCard,
        NModal,
        NAlert,
        NDivider,
        NCollapse,
        NSkeleton,
        GridTable,
        SuperTable,
        ButtonGroup,
        FilterCoins,
        FilterAlgos,
        GridSettings,
        BotReviewSell,
        NCollapseItem,
        FilterExchanges,
        FilterChangedDate,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
.sell-info {
  :deep(p):not(:first-child) {
    margin-top: 14px;

    &[style='color:red'] {
      color: rgb(208, 58, 82) !important;
    }
  }

  :deep(b) {
    color: #4949D9;
  }

  :deep(a) {
    text-decoration: underline;
  }

  :deep(ul) {
    margin-top: 14px;
    list-style: circle;
    padding-left: 24px;
  }
}
</style>